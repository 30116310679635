import React, { useMemo, useState } from "react"
import { Modal } from "react-bootstrap"
import ShowMoreText from "react-show-more-text"
import { Swiper, SwiperSlide } from "swiper/react"
import config from "../../../config/config"
import { isLoggedIn, getUser } from "../../../services/auth"

const QuickViewModalHomeDesignData = ({ show, handleClose, item, hidePricingGlobally , handleShowAddBoard}) => {
  const [slideOption, setSlideOption] = useState(null)
  const [isReadmore, setReadmore] = useState(true)
  const changePopupSlideImage = slide => {
    setSlideOption(slide)
  }
  const [addedToBoard, setAddedToBoard] = useState(false)
  var boards = []
  if(item.ProductID){
      item.objectID = item.ProductID 
  }
  var className = item.className ? item.className : item.ClassName
  if (isLoggedIn()) {
    var user = getUser()
    boards = Object.keys(user.Boards).map(key => user.Boards[key])
    var y = 0
    for (; boards[y]; ) {
      let _board = boards[y]
      let _board_items = _board.Items
      let _board_items_objs = JSON.parse(_board_items)
      let _board_items_arr = _board_items_objs
        ? Object.keys(_board_items_objs).map(key => _board_items_objs[key])
        : []
      let _item_exist = _board_items_arr.find(function(x){
          if(item.vt){
              if(typeof item.vt === 'object'){
                return x.ID === item.objectID && x.VariantID === item.vt.objectID
              }else if(typeof item.vt === 'number'){
                  return x.ID === item.objectID && x.VariantID === item.vt
              }              
          }else{
            return x.ID === item.objectID
          }          
      })
      //console.log(_item_exist)
      if (_item_exist && !addedToBoard) {
        setAddedToBoard(true)
      }
      y++
    }
  }
  
  const modal = useMemo(() => {
    const swiper_params = {
      spaceBetween: 10,
      navigation: {
        prevEl: ".swiper-button-prev-unique",
        nextEl: ".swiper-button-next-unique",
      },
      breakpoints: {
        1400: {
          slidesPerView: 5,
        },
        1200: {
          slidesPerView: 4,
        },
        992: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 2,
        },
        0: {
          slidesPerView: 1,
        },
      },
    }
    const host = config.host
    var isShowing = show
    const valid_status = ["Active"]
    var image_path
    var other_options = []
    var vt = null
    var link = null
    var lotSize
    var floorplans
    var isDoubleStorey = false    
    if(className === 'BoardImage'){        
        var product = item.ProductData
        item.name = product.Name
        item.about = null
        if(item.ProductType === 'HomeDesign'){
            item.about = product.Description
        }        
        link = product.Link
        image_path = item.ImagePath
        if(product.vt){
            item.groundFloorImagePath = product.vt.GroundFloorImagePath
            item.firstFloorImagePath = product.vt.FirstFloorImagePath
            
            var boardItem = {
                name: product.vt.Name,
                bed: product.vt.Bed,
                bath: product.vt.Bath,
                garage: product.vt.Garage,
                living: product.vt.Living,
            }
            if(!slideOption) setSlideOption(boardItem)
            lotSize = product.vt.LotWidth + " x " + product.vt.LotLength + "m"
            item.designersName = product.designersName
            item.lotWidth = product.vt.LotWidth
            item.lotLength = product.vt.LotLength
            item.lotSize = lotSize
            item.homeArea = product.vt.HomeArea
            if(typeof product.vt.RRPPrice !== 'undefined')
                item.formattedPrice = "$" + product.vt.RRPPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            isDoubleStorey = product.isDoubleStorey
        }        
        
    }else{
        image_path = item.Image
        link = item.link
        floorplans = item.Floorplans
        item.formattedPrice = item.vt.rrp
        if (typeof floorplans !== undefined && floorplans) {
          var i = 0
          for (; floorplans[i];) {
            var option = floorplans[i].UndigitalFloorplan
            option.Image = option.groundFloorImagePath
            var isValid = valid_status.includes(option.status)
            if (isValid) {
              other_options.push(option)
            }
            i++
          }
        }      
        if(item.vt){
            if(typeof item.vt === 'object'){
                if(item.vt.groundFloorImagePath){
                    image_path = item.vt.groundFloorImagePath
                    item.groundFloorImagePath = item.vt.groundFloorImagePath
                }
                if(item.vt.firstFloorImagePath){
                    image_path = item.vt.firstFloorImagePath
                    item.firstFloorImagePath = item.vt.firstFloorImagePath
                }
                link = `${item.link}${item.vt ? `/?vid=${item.vt.objectID}` : ''}`
                setSlideOption(item.vt)
            }else if(typeof item.vt === 'number'){
                link = `${item.link}/?vid=${item.vt}`
            }
        }
        var _storeys = ""
        var storeysList = item.Storeys
        if (storeysList !== null && typeof storeysList !== 'undefined') {
          _storeys = storeysList.UndigitalStoreys.name
          if (_storeys === 'Double Storey') {
            isDoubleStorey = true;
          }
        }
        lotSize = item.lotWidth + " x " + item.lotLength + "m"
        item.lotSize = lotSize
        item.about = item.description
    }
    item.isDoubleStorey = isDoubleStorey
    return <Modal
      show={isShowing}
      onHide={handleClose}
      onShow={resize}
      size={"xl"}
      animation={true}
      className="itemModal"
    >
      <div className="row mb-4">
        <div className="col-12">
          <div
            className="btn-close d-lg-block"
            onClick={handleClose}
          ></div>
          <div className="main-title-wrap">
            <div className="board-page-title">
              <h1 className="product-name-title text-uppercase h2">
                {item.name}
              </h1>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8">
          {item.about !== '' && (
            <div className="home-design-info">
              <span dangerouslySetInnerHTML={{ __html: item.about }} /> 
            </div>
          )}
        </div>
        <div className="col-12 col-lg-4">
          <h3 className="info-title">Information</h3>
          <div className="list-group list-group-flush mb-4">
            {item.designersName && (
            <div className="list-group-item px-0">
              <div className="row align-items-center">
                <div className="col">
                  <h5 className="mb-0">Designer</h5>
                </div>
                <div className="col-auto">
                  <p className="font-weight-medium">
                    {item.designersName}
                  </p>
                </div>
              </div>
            </div>
            )}
            {(item.formattedPrice && hidePricingGlobally == 0) && (
            <div className="list-group-item px-0">
              <div className="row align-items-center">
                <div className="col">
                  <h5 className="mb-0">Price from*</h5>
                </div>
                <div className="col-auto">
                  <p className="font-weight-medium">
                    {item.formattedPrice}
                  </p>
                </div>
              </div>
            </div>
            )}
            {item.homeArea && (
            <div className="list-group-item px-0">
                <div className="row">
                  <div className="col">
                    <h5 className="mb-0">Approx House area</h5>
                  </div>
                  <div className="col-auto">
                    <p>{item.homeArea} sqm</p>
                  </div>
                </div>
              </div>
              )}
              {(item.lotWidth && item.lotLength) && (
              <div className="list-group-item px-0">
                <div className="row">
                  <div className="col">
                    <h5 className="mb-0">Minimum block dimensions</h5>
                  </div>
                  <div className="col-auto">
                    <p>{item.lotSize}</p>
                  </div>
                </div>
              </div>
              )}
          </div>
        </div>
        <div className="col-12 col-lg-12">
          <div
            className="d-flex flex-row justify-content-between align-items-end board-page-title
          home-design-subtitle"
          >
            <div>
              <span className="product-name-span">layout</span>
              <h3 className="product-name-title">{slideOption ? slideOption.name : item.name}</h3>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            {slideOption && (
            <div className="d-flex mx-n2 mb-2 mb-sm-0">
              <div className="mx-2 item-feature">
                {slideOption.bed}
                <svg width="26" height="26">
                  <use xlinkHref="/img/icons.svg#icon-bed-stroke"></use>
                </svg>
              </div>
              <div className="mx-2 item-feature">
                {slideOption.bath}
                <svg width="26" height="26">
                  <use xlinkHref="/img/icons.svg#icon-bath-stroke"></use>
                </svg>
              </div>
              <div className="mx-2 item-feature">
                {slideOption.living}
                <svg width="26" height="26">
                  <use xlinkHref="/img/icons.svg#icon-sofa-stroke"></use>
                </svg>
              </div>
              <div className="mx-2 item-feature">
                {slideOption.garage}
                <svg width="26" height="26">
                  <use xlinkHref="/img/icons.svg#icon-garages-stroke"></use>
                </svg>
              </div>
            </div>
            )}
          </div>
          {item.isDoubleStorey ? (
            <div className="browser-img-wrap">
                <div className="container">
                    <div className="row">
                      {item.groundFloorImagePath && (
                        <div className="col-6">
                        <img
                          src={item.groundFloorImagePath}
                          alt={item.name}
                          className="w-100"
                        />
                        </div>
                      )}
                      {item.firstFloorImagePath && (
                        <div className="col-6">
                        <img
                          src={item.firstFloorImagePath}
                          alt={item.name}
                          className="w-100"
                        />
                        </div>
                      )}  
                    </div>
                  </div>
            </div>
          ) : (
            <div className="browser-img-wrap">
              {item.groundFloorImagePath && (
                <img
                  className="mw-100"
                  src={item.groundFloorImagePath}
                  alt={item.name}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div className="text-center my-lg-5 my-4">
        <a href={link} className="btn btn-black btn-lg btn-w210">
          VIEW DESIGN
        </a>
      </div>
    </Modal>
  }, [show, handleClose, item, hidePricingGlobally, slideOption])

  function resize() {
    window.dispatchEvent(new Event("resize"))
  }

  return <>{modal}</>
}

export default QuickViewModalHomeDesignData
